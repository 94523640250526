import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import WebinarWatchPage from '../views/WebinarWatchPage.vue'

const routes = [
  {
    path: '/watch/:showCode/:sessionCode',
    name: 'WatchWebinar',
    component: WebinarWatchPage,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
